<template>
    <div class="container">
        <div class="main-card">
            <div class="card-info">
                <div class="card-column">
                    <img src="../assets/images/logo.jpg" alt="" class="card-logo">
                </div>
                <div class="card-column">
                    <div class="card-name" @click="copyName">{{username}}<span class="username-discrim">#{{discrim}}</span></div>
                    <div class="card-description">world is cat</div>
                </div>
            </div>
            <div class="main-links">
            <a href="https://discordapp.com/users/675714186898309133" class="btn" style="color: #5865F2;"><i class="fa-brands fa-discord"></i></a>
            <a href="https://t.me/swdmeow" class="btn" style="color: #0088cc;"><i class="fa-brands fa-telegram"></i></a>
            <a href="https://qiwi.com/n/SWDCHW" class="btn" style="color: #8E00BC;"><i class="fa-solid fa-circle-dollar-to-slot"></i></a>
        </div>
        </div>

        <div class="successCopy" id="successCopy">
            <div class="success-title"><i class="fa-solid fa-hexagon-check"></i> Успешно!</div>
            <div class="success-text">Вы успешно скопировали текст <b class="">{{username}}#{{discrim}}</b></div>
        </div>
    </div>
</template>

<script>
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
    name: "SMain",
    data() {
        return {
            username: "swd",
            discrim: "0247",
            success: false
        }
    },
    methods: {
        async copyName() {
            const copyText = this.username+"#"+this.discrim
            var area = document.createElement('textarea');
            document.body.appendChild(area);  
            area.value = copyText;
            area.select();
            document.execCommand("copy");
            document.body.removeChild(area); 
            let alert = document.getElementById('successCopy')
            switch(this.success) {
                case true:
                    break;
                case false:
                    alert.classList.add('active');

                    // for (let i = 0; i < 5; i++) {
                    //     setTimeout(() => {
                    //         alert.classList.add('active');
                    //     }, 1000);
                    // }
                    
                    await sleep(5000)
                    alert.classList.remove('active');
                    this.success = false
                    break;
            }
        }
    }
}
</script>

<style>
@font-face {
    src: url(../assets/fonts/Ginto-Black.ttf);
    font-family: "Ginto";
}
body {
    color: rgb(22, 22, 22);
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background: url(https://cdn.discordapp.com/attachments/934101013458194533/935205262938222613/OxygenNotIncluded_AMJKihAxd3.jpg);
    backdrop-filter: blur(10px);
}


.container {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    align-items: center;
}

.successCopy {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 20px;
    border-radius: 0.5rem;
    background: rgb(0, 226, 0, 0.5);
    opacity: 0;
    transition: all .4s ease-in;
}
.successCopy.active {
    opacity: 1;
}

.success-title .fa-solid {
    color: #5865F2;
    font-size: 20px;
}
.main-card {
    padding: 20px;
    border-radius: .5rem;
    /* background: linear-gradient(to right, rgb(255, 41, 87, 0.4), rgb(255, 43, 255, 0.4), rgb(165, 68, 255, 0.4)); */
    background: rgba(29, 29, 29, 0.65);
}

.card-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-logo {
    border-radius: 50%;
    width: 80px;
    /* box-shadow: 0 0 10px #202020; */

    margin-right: 20px;
}

.card-name {
    font-size: 25px;
    font-family: "Ginto";
    color: white;
    cursor: pointer;
}

.username-discrim {
    color: rgb(179, 179, 179);
}

.card-description {
    color: rgb(192, 192, 192);
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
}

.main-links {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
}

.btn {
    font-size: 22px;
    color: #5f5f5f;
    transition: .2s ease-in-out;
}

.btn:hover {
    transform: translateY(-2px);
}
</style>
